.App {
  overflow-x: hidden;
  width: 100%;
  position: relative;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
} 