/* Update the main container */
.home {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  color: #333;
}

/* Update profile section to ensure it doesn't interfere */
.profile-section {
  text-align: center;
  margin-bottom: 2rem;
}

.profile-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 0 auto 2rem;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.tagline {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 1rem;
}

.sndline {
  font-size: 1.1rem;
  color: #666;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.social-links a {
  color: #333;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-icon {
  width: 40px;
  height: 40px;
  fill: currentColor;
}

.social-links a:hover {
  color: #007bff;
  transform: translateY(-2px);
}

/* Divider styling */

/* Divider styling */
.section-divider {
  position: relative;
  width: 100vw;
  left: calc(-50vw + 50%);
  height: 1px;
  border: none;
  margin: 1.5rem 0;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.7) 30%,
    rgba(0, 0, 0, 0.85) 50%,
    rgba(0, 0, 0, 0.7) 70%,
    rgba(0, 0, 0, 0)
  );
}


.timeline {
  margin-top: 2rem;
  position: relative;
  padding-left: 10rem;
}

.timeline::before {
  content: '';
  position: absolute;
  left: 9rem;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #eaeaea;
}

.timeline-item {
  display: flex;
  margin-bottom: 1.5rem;
  text-align: left;
  position: relative;
  padding-top: 0.7rem;
}

.timeline-item::before {
  content: '';
  position: absolute;
  left: -1.2rem;
  top: 1.5rem;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #666;
  transform: translateX(1px);
  z-index: 1;
}

.year-container {
  position: absolute;
  left: -11rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 9.4rem;
  top: 0.3rem;
}

.year {
  font-weight: 400;
  color: #666;
  font-size: 0.75rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 55px;
}

.institution-logo {
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 1px;
}

.institution-logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  width: 65px;
  height: 65px;
}

.content {
  flex: 1;
  font-size: 0.85rem;
  line-height: 1.4;
  padding-top: 0.3rem;
}

.content p {
  margin-bottom: 0.15rem;
  margin-top: 0;
}

.content p p {
  margin-top: 0;
  margin-bottom: 0.2rem;
}

.content a {
  color: #8a2be2;
  text-decoration: none;
  border-bottom: 2px solid #8a2be2;
  padding-bottom: 1px;
  transition: all 0.2s ease-in-out;
  font-weight: 500;
}

.content a:hover {
  color: #9f50ea;
  border-bottom-color: #9f50ea;
}

.content strong {
  font-size: 0.9rem;
  font-weight: 600;
}

.content ul {
  margin-top: 0.4rem;
  margin-bottom: 0.8rem;
}

.content li {
  font-size: 0.85rem;
  margin-bottom: 0.3rem;
}

/* Adjust first paragraph spacing */
.content p:first-child {
  margin-top: 0;
  padding-top: 0;
}

/* Add responsiveness */
@media (max-width: 600px) {
  .timeline {
    padding-left: 2rem;
  }

  .timeline::before {
    left: 1rem;
  }

  .timeline-item::before {
    left: -1rem;
  }

  .year-container {
    position: relative;
    left: 0;
    margin-bottom: 0.5rem;
    width: auto;
  }

  .timeline-item {
    flex-direction: column;
  }
}

/* Keep first paragraph aligned properly */
.content p:first-child {
  margin-top: 0;
  padding-top: 0;
}

/* Add slightly more space before a new section */
.content p:last-child {
  margin-bottom: 0.2rem;
}

.sub-position {
  position: relative;
  padding-left: 0;
  margin-top: 1rem !important;
}

.sub-position::before {
  content: '';
  position: absolute;
  left: -1.2rem;
  top: 0.5rem;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #666;
  transform: translateX(1px);
  z-index: 1;
}

.nearsoft-logo {
  width: 58.5px;  /* 90% of 65px */
  height: 58.5px;  /* 90% of 65px */
}

.nearsoft-logo img {
  width: 58.5px;  /* 90% of 65px */
  height: 58.5px;  /* 90% of 65px */
}

/* Adjust last item to not have bottom margin */
.timeline-item:last-child {
  margin-bottom: 0;
}

.featured-talks {
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: #faf7f7;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.featured-talks h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1.5rem;
  font-weight: 500;
}

.talks-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.talk-item {
  background: white;
  border-radius: 1px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s ease-in-out;
}

.talk-item:hover {
  transform: translateY(-2px);
}

.talk-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.talk-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.talk-content {
  padding: 1rem;
}

.talk-content h3 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.talk-content p {
  font-size: 0.85rem;
  color: #666;
  margin: 0;
}

/* Responsive adjustments */
@media (max-width: 900px) {
  .talks-grid {
    grid-template-columns: 1fr;
  }
  
  .featured-talks {
    padding-left: 2rem;
  }
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.8rem;
  margin-top: 0.8rem;
}

.video-item {
  position: relative;
  border-radius: 1px;
  overflow: hidden;
  aspect-ratio: 16/9;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.video-item:hover {
  transform: translateY(-2px);
}

.video-item video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 0.5rem;
}

.video-overlay p {
  color: white;
  font-size: 0.75rem;
  margin: 0;
  text-align: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .video-grid {
    grid-template-columns: 1fr;
  }
}

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  width: 80vw;
  max-width: 1000px;
}

.modal-content video {
  width: 100%;
  max-height: 90vh;
  object-fit: contain;
}

.close-button {
  position: absolute;
  top: -40px;
  right: -40px;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  padding: 10px;
}

.close-button:hover {
  color: #ddd;
}

/* Prevent body scroll when modal is open */
body.modal-open {
  overflow: hidden;
}

.presentation-grid {
  display: grid;
  grid-template-columns: repeat(2, 0.8fr);
  gap: 1rem;
  margin-top: 1.5rem;
  justify-content: center;
}

.presentation-item {
  width: 80%;
  margin: 0 auto;
  position: relative;
  border-radius: px;
  overflow: hidden;
  aspect-ratio: 4/3;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s ease-in-out;
}

.presentation-item:hover {
  transform: translateY(-2px);
}

.presentation-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.presentation-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 0.5rem;
}

.presentation-overlay p {
  color: white;
  font-size: 0.75rem;
  margin: 0;
  text-align: center;
}

@media (max-width: 768px) {
  .presentation-grid {
    grid-template-columns: 1fr;
  }
}

.media-container {
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  gap: 3.5rem; /* Increased from 2rem to 3.5rem */
  margin-top: 1.5rem;
}

.video-section {
  width: 100%;
}

.video-section .video-item {
  aspect-ratio: 16/10;
  height: 100%;
}

.presentations-section {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  justify-content: space-between;
  height: 100%;
  padding-right: 1rem; /* Added padding to push images slightly right */
}

.presentation-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.3rem;
}

.presentation-item {
  width: 100%;
  position: relative;
  border-radius: 1px;
  overflow: hidden;
  aspect-ratio: 16/9;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s ease-in-out;
}

.presentation-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .media-container {
    grid-template-columns: 1fr;
  }
}

.pet-projects {
  margin-top: 2rem;
  background-color: #ffffff; /* Set a light background color */
  padding: 1rem; /* Add padding for better spacing */
  border-radius: 8px; /* Optional: Add rounded corners */
}

.project-list {
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove padding */
}

.project-item {
  display: flex;
  align-items: center; /* Align items vertically */
  margin-bottom: 0.5rem; /* Reduced space between projects */
}

.project-item img {
  width: 80px; /* Set a fixed width for images */
  height: 80px; /* Set a fixed height for images */
  border-radius: 4px; /* Optional: Add rounded corners */
  margin-right: 0.5rem; /* Space between image and text */
}

.project-content {
  font-size: 0.9rem; /* Adjust font size */
}

.project-item p {
  font-size: 0.9rem;
}